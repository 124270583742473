const returnButton = document.querySelector('.return-to-top');

export default function () {
    const showButtonSentinel = document.querySelector('.return-to-top-sentinel');
    if (!returnButton || !showButtonSentinel || window.innerWidth < 1279) return;

    trackScroll();

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {

            if (entry.isIntersecting) {
                returnButton.classList.remove("show");
                return;
            }

            setTimeout(function () {
                returnButton.classList.add('show');
                returnButton.style.position = 'fixed';
                calculateScrollPercent();
            }, 150);

        });
    });
    observer.observe(showButtonSentinel);

    returnButton.addEventListener("click", function () {
        returnButton.style.setProperty('--scrollProgress', `0%`);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    });
}

function trackScroll() {
    let debounceTimeout;

    window.addEventListener('scroll', function () {

        clearTimeout(debounceTimeout);
        debounceTimeout = setTimeout(function () {
            calculateScrollPercent();
        }, 25);
    });
}

function calculateScrollPercent() {
    const scrollTop = window.scrollY;
    const pageHeight = document.documentElement.scrollHeight - window.innerHeight;
    const percentScrolled = (scrollTop / pageHeight) * 100;
    returnButton.style.setProperty('--scrollProgress', `${percentScrolled}%`);
}
