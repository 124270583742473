import { getScript, isVisible } from './utils/utils';


const BuildRecaptcha = (captchaWrapper) => {
    window.grecaptcha.render(captchaWrapper, {
        'callback': function (result) {
         
            const container = captchaWrapper.closest('[data-recaptcha-form]');
            container
                .querySelectorAll('.confirm-button[disabled]')
                .forEach(button => button.disabled = false);
        }
    });
};

var defferedCaptchas = [];

window.recaptchaLoadCallback = function () {

    for (let value of defferedCaptchas)
        BuildRecaptcha(value);
    defferedCaptchas = [];
};

export function DrawInlineCaptcha() {
    const captchaWrappers = document.querySelectorAll('[data-recaptcha-form] .captcha-wrapper');

    captchaWrappers.forEach((item) => {
        if (isVisible(item))
            DrawSingleCaptcha(item);
    });

}

export function DrawSingleCaptcha(target) {

    if (!target)
        return;

    if (typeof window.grecaptcha === 'undefined') {
        defferedCaptchas.push(target);

        getScript('https://www.google.com/recaptcha/api.js?render=explicit&onload=recaptchaLoadCallback', null);

    }
    else BuildRecaptcha(target);



}